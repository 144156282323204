/* General Styles */
body {
    font-family: 'Cormorant Garamond', serif;
    color: black;
    margin: 0;
    padding: 0;
    overflow-y: auto;
}

.portfolio-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    min-height: 100vh;
    position: relative;
}

/* Corner Icon Styling */
.corner-icon {
    position: fixed;
    top: 20px;
    left: 20px;
    width: 50px;
    height: auto;
    z-index: 10;
    transition: opacity 0.3s ease;
}

/* Black Transition Effect */
.black-transition {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: black;
    z-index: 1000;
    animation: fadeToBlack 0.5s forwards;
}

@keyframes fadeToBlack {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

/* Scroll Selector Styling */
.scroll-selector {
    display: flex;
    overflow-x: auto;
    gap: 20px;
    padding: 10px 20px;
    margin: 20px 0;
    scroll-behavior: smooth;
    white-space: nowrap;
    border-bottom: 2px solid #ddd;
}

.scroll-tab {
    font-family: 'Cormorant Garamond', serif;
    font-size: 1.5em;
    padding: 6px 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
    transition: color 0.3s;
    letter-spacing: -1px;
}

.scroll-tab.active {
    color: #333;
    font-weight: bold;
    border-bottom: 2px solid #333;
}

.scroll-tab:hover {
    color: #333;
}

/* Gallery Layout */
.gallery {
    padding-top: 5%;
    display: flex;
    flex-direction: column;
    gap: 60px;
    align-items: center;
    width: 100%;
    overflow-y: auto;
    max-height: 100vh;
    scroll-snap-type: y mandatory;
    padding-bottom: 100px; /* Increased padding for better scrolling space */
}

/* Gallery Item Styling */
.gallery-item {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.8s ease-out, transform 0.8s ease-out;
    text-align: left;
    scroll-snap-align: center;
    padding: 20px;
    max-width: 90%;
    margin: 0 auto;
}

.gallery-item.show {
    opacity: 1;
    transform: translateY(0);
}

/* Image Styling */
.gallery-image {
    max-width: 100%;
    filter: grayscale(100%);
    transition: filter 1s ease, opacity 1s ease;
    width: var(--image-width, 50%);
}

.gallery-item.show .gallery-image {
    filter: grayscale(0%);
    opacity: 1;
}

/* Description Block Styling */
.description-container {
    max-width: 300px;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.image-caption {
    font-size: 1.5em;
    color: #333;
    font-weight: bold;
    margin-bottom: 10px;
}

.description-text {
    font-size: 1em;
    color: #555;
    margin-bottom: 10px;
}

.details {
    font-size: 0.9em;
    color: #666;
    margin-top: 5px;
}

.details em {
    font-style: italic;
    font-weight: bold;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
    /* Stacked layout for mobile */
    .gallery {
        padding-top: 10%; /* Adjusted padding to make scrolling smoother */
        padding-left: 0%;
        padding-right: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .gallery-item {
        flex-direction: column;
        height: auto;
        text-align: center;
        padding: 10px;
        margin: 10px 2%;
        max-width: 96%;
    }

    .gallery-image {
        width: 100% !important;
        max-width: 100%;
        margin-bottom: 10px;
    }

    .description-container {
        max-width: 96%;
        margin: 0 auto;
        padding: 8px;
        font-size: 1em;
        line-height: 1.5;
        letter-spacing: -0.5px;
        text-align: justify;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: none;
        -webkit-box-orient: vertical;
    }

    .image-caption {
        font-size: 1em;
        margin: 5px 0;
    }

    .description-text,
    .details {
        font-size: 0.85em;
        margin: 5px 0;
    }

    /* Adjust scroll selector styling for mobile */
    .scroll-tab {
        font-size: 1em;
        padding: 4px 6px;
        letter-spacing: -0.5px;
    }

    .scroll-selector {
        padding: 5px 10px;
        margin: 10px 0;
    }
}
