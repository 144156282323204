/* Import the custom font */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&display=swap');

body, .me-container {
    font-family: 'Cormorant Garamond', serif;
    font-weight: bold;
    color: black;
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.divider {
    width: 20%; /* Slightly narrower than the container for visual balance */
    margin: 20px auto; /* Center it and add spacing */
    border: none;
    border-top: 2px solid black; /* Light, thin line */
    opacity: 0.8; /* Slight transparency for subtlety */
}

/* Position the icon in the top left corner */
.corner-icon {
    position: fixed;
    top: 2vh; /* Responsive top margin */
    left: 2vw; /* Responsive left margin */
    width: 6vw; /* Width adjusts based on viewport width */
    max-width: 80px; /* Limit maximum width on larger screens */
    height: auto;
    z-index: 10; /* Ensures it stays above other content */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    .corner-icon {
        width: 8vw;
        max-width: 60px;
    }
}

@media (max-width: 480px) {
    .corner-icon {
        width: 10vw;
        max-width: 50px;
    }
}

/* Main content container */
.me-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    padding: 20px;
    text-align: center;
}

/* Description styling */
.description {
    font-size: 1.5em;
    margin: 10px 0 30px;
    max-width: 500px;
    line-height: 1.4;
    letter-spacing: -2px;
    text-align: justify; /* Align text for readability */
    overflow: visible; /* Allow content to display fully */
    height: auto; /* Let the content expand naturally */
}

/* Profile Image */
.profile-image {
    margin-top: 50px;
    width: 500px;
    height: auto;
    max-width: 50vw; /* Adjusts with viewport width */
    max-height: 50vh; /* Adjusts with viewport height */
    margin-bottom: 20px;
}

/* Further adjustments for smaller screens */
@media (max-width: 768px) {
    .description {
        max-width: 305px;
    }
    .profile-image {
        max-width: 500px;
    }
}

@media (max-width: 480px) {
    .description {
        max-width: 305px;
        letter-spacing: -2px;
    }
    .profile-image {
        max-width: 300px;
    }
}

.icon-image {
    width: 24px;
    height: 24px;
    transition: transform 0.3s;
}

.icon-image:hover {
    transform: scale(1.1);
}

.social-links {
    display: flex;
    gap: 45px;
    justify-content: center;
    margin-top: 15px;
}

.social-links a {
    color: black;
    font-size: 2em;
    transition: color 0.3s;
    text-decoration: none;
}

.social-links a:hover {
    color: #0690DB;
}

@media (max-width: 768px) {
    .social-links a {
        font-size: 1.3em;
    }
}

@media (max-width: 480px) {
    .social-links a {
        font-size: 1.2em;
    }
}

/* Transition overlay */
.fade-transition {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    z-index: 9999;
    animation: fadeOut 0.8s forwards;
}

/* Keyframes for fade-out effect */
@keyframes fadeOut {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
