/* Updated CSS for placing links at the inner boundaries of the image */

/* Reset for all elements */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

/* Ensuring the entire document fills the viewport */
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
    overflow: hidden;
}

.home-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-color: white;
    position: relative;
    flex-direction: column;
}

/* Centered image settings */
.centered-image {
    background-image: url('/public/images/background.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    width: 40vw; /* Scale relative to viewport */
    max-width: 500px; /* Prevent it from growing too large */
    aspect-ratio: 1 / 1; /* Keep it square */
}

/* Import the custom font */
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@400;700&display=swap');

/* Apply Cormorant Garamond */
body, .name-overlay, .link-container {
    font-family: 'Cormorant Garamond', serif;
}

/* Styling for name overlay */
.name-overlay {
    position: relative; /* Relative to container */
    width: 40vw; /* Scale relative to viewport */
    max-width: 500px; /* Prevent it from growing too large */
    aspect-ratio: 7 / 2; /* Proportional to its original size */
    background-image: url('/public/images/signed.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: -20px; /* Adjust spacing to align above the centered image */
}

/* Adjust for mobile devices */
@media (max-width: 768px) {
    .centered-image {
        width: 70vw;
    }
    .name-overlay {
        width: 60vw;
    }
    .link-container {
        width: 70vw;
    }
}

@media (max-width: 480px) {
    .centered-image {
        width: 80vw;
    }
    .name-overlay {
        width: 70vw;
    }
    .link-container {
        width: 80vw;
        font-size: 1.8em; /* Scale down for smaller screens */
    }
}

/* Link container aligned inside centered image width */
.link-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; /* Span the entire width below the image */
    max-width: 500px; /* Match centered-image width */
    margin-top: 10px;
    font-weight: bold;
    font-size: 1.8em;
    letter-spacing: -4px;
    position: relative;
    z-index: 15; /* Ensure links are above other elements */
}

.link-container a {
    text-decoration: none;
    color: black;
    cursor: pointer; /* Ensures pointer on hover */
    flex-basis: auto; /* Allow the links to adjust dynamically */
}

/* Adjusted specific positioning with responsive scaling for "Me" and "My Work" links */
.link-container a:first-child {
    margin-left: 17%; /* Default scaling for larger screens */
}

.link-container a:last-child {
    margin-right: 18%; /* Default scaling for larger screens */
}

/* Adjust for tablet devices */
@media (max-width: 768px) {
    .link-container a:first-child {
        margin-left: 18%; /* Adjust for smaller screen sizes */
    }

    .link-container a:last-child {
        margin-right: 18%; /* Adjust for smaller screen sizes */
    }
}

/* Adjust for mobile devices */
@media (max-width: 480px) {
    .link-container a:first-child {
        margin-left: 24%; /* Default scaling for larger screens */
    }
    
    .link-container a:last-child {
        margin-right: 25%; /* Default scaling for larger screens */
    }
}
